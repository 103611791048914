import { NgStyle, NgIf, NgClass } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'pb-image',
    templateUrl: './pb-image.component.html',
    styleUrls: ['./pb-image.component.scss'],
    standalone: true,
    imports: [NgStyle, NgIf, NgClass]
})
export class PbImageComponent implements OnInit {

  constructor() { }

  @Input() src:string;
  @Input() alt:string;
  @Input() width: string = "100%";
  @Input() height: string = "100%";
  @Input() imageWidth:number = 480;
  @Input() imageHeight:number = 365;
  @Input() lazyLoad:boolean = true;
  @Input() crop:string="true";
  @Input() cover:boolean=false;


  server:string;

  ngOnInit(): void {
      this.server = environment.mediaServerUrl + "/"; 
  }


  
  get imageSource(){
    if (this.src){
      if (this.src.indexOf('svg+xml', 0)==-1 && this.src.indexOf('https://', 0)==-1){
        return this.server + (this.crop=="true"? "c":"") + this.imageWidth + "x" + this.imageHeight + this.src;
      }
      else if (this.src.indexOf('media.placebuzz.com', 0)>-1){
        return this.src.replace("/photo/", (this.crop=="true"? "c":"") + this.imageWidth + "x" + this.imageHeight + "/photo/");
      }
      else{
        return this.src;
      }
      
    }
    return "";
  }

  get srcset(){
    if (this.src){
      if (this.src.indexOf('https://', 0)==-1){
        var loRes = this.server + (this.crop=="true"? "c":"") + this.imageWidth + "x" + this.imageHeight + this.src;
        var hiRes = this.server + (this.crop=="true"? "c":"") + this.imageWidth*2 + "x" + this.imageHeight*2 + this.src;
    
        return loRes + " 1x, " + hiRes + " 2x";
      }
      else{

        return this.src;

      }  
    }
    return "";
  }

  errorState:boolean = false;


  setNoImage(){
    
    this.errorState = true;
      
  }
}


